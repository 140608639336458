import { Flex, FlexProps } from '@chakra-ui/core';
import React, { ReactChildren, ReactChild } from 'react';
// import theme from '../gatsby-plugin-chakra-ui/theme';

export const Container = ({
	children,
	...rest
}: {
	children?: ReactChildren | ReactChild;
	rest?: FlexProps;
}) => {
	return (
		<Flex
			flexDir="column"
			// w={{
			// 	// sm: theme.sizes.containers.sm,
			// 	md: theme.sizes.containers.md,
			// 	lg: theme.sizes.containers.lg,
			// 	xl: theme.sizes.containers.xl,
			// }}
			overflowX="hidden"
			width={[
				'100%', // base
				'95%', // 480px upwards
				'90%', // 768px upwards
				'70%', // 992px upwards
			]}
			justifyContent="center"
			alignItems="center"
			mx="auto"
			{...rest}
		>
			{children}
		</Flex>
	);
};
