import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import MetaImage from '../../static/meta-image.png';

// import { UseSite, api } from '../utils/api';

export const SEO = ({ description, lang, meta, title }) => {
	// This is currently bugged, so we're just using a static obj. for now
	// https://github.com/gatsbyjs/gatsby/issues/21392

	//
	// const { site } = useStaticQuery(
	// 	graphql`
	// 		{
	// 			site {
	// 				siteMetadata {
	// 					title
	// 					description
	// 					author
	// 				}
	// 			}
	// 		}
	// 	`
	// );

	const site = {
		siteMetadata: {
			title: 'Thompson',
			description: '',
		},
	};

	// const site = api.UseSite;

	const metaDescription = site.siteMetadata.description || description;

	// console.log(site.siteMetadata, metaDescription);

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title || site.siteMetadata.title}
			titleTemplate={title ? `%s | ${site.siteMetadata.title}` : ``}
			meta={[
				// This one doesn't seem to work :(
				// {
				// 	property: `description`,
				// 	content: metaDescription
				// },
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				// Note twitter tags have been removed, might want later
			].concat(meta)}
		>
			<link
				href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
				rel="stylesheet"
			></link>
			<link
				rel="shortcut icon"
				href="/favicon/favicon.ico"
				type="image/x-icon"
			/>
			<link rel="icon" href="/favicon/favicon.ico" type="image/x-icon" />
			<meta name="description" content={metaDescription} />
			<meta
				property="og:image"
				content={MetaImage}
				// content="https://og-image.now.sh/test.png?theme=dark&md=1&fontSize=150px&images=https%3A%2F%2Fassets.vercel.com%2Fimage%2Fupload%2Ffront%2Fassets%2Fdesign%2Fvercel-triangle-white.svg&widths=150&heights=150"
			/>
			<meta property="og:image:type" content="image/png" />
			<meta property="og:image:width" content="1127" />
			<meta property="og:image:height" content="687" />
		</Helmet>
	);
};

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
};
