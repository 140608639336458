import styled from '@emotion/styled';
import { Link, Button as ChakraButton } from '@chakra-ui/core';

export const Button = styled(ChakraButton)`
	cursor: pointer;
	-webkit-appearance: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	user-select: none;
	transition: 0.2s ease;
	border-radius: 4px;
	font-weight: normal;

	/* Primary/Secondary variants  */
	color: ${(props) => (props.secondary ? '#4eb75d' : 'white')};
	background: ${(props) => (props.secondary ? 'none' : '#4eb75d')};
	border: ${(props) => (props.secondary ? '1px solid #4eb75d' : 'none')};

	&:focus {
		outline: none;
	}

	&:disabled {
		background: gray;
	}

	&:hover {
		transform: scale(1.06);
		color: ${(props) => (props.secondary ? '#4eb75d' : 'white')};
		background: ${(props) => (props.secondary ? 'none' : '#4eb75d')};
	}
`;
