/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { Box, Heading, Flex, Text, Link, Image } from '@chakra-ui/core';
import { GradientHeading } from './.discard/gradientHeading';
import { Button } from './button';
import HamburgerMenu from 'react-hamburger-menu';
import Logo from '../../static/logo.svg';

const MenuItem = ({ children, urlString }) => (
	<Link
		// mt={{ base: 4, md: 0 }}
		mr={6}
		mt={{ base: 4, lg: 0 }}
		my={{ lg: 'auto' }}
		display="block"
		fontFamily="Fira Sans"
		href={urlString}
		css={css`
			color: #222b45;
			opacity: 0.8;
			text-decoration: none;
			transition: all 0.2s ease;
			&:hover {
				opacity: 1;
				transform: scale(1.02);
				text-decoration: none;
			}
		`}
	>
		{children}
	</Link>
);

const MenuItems = (props) => {
	return (
		<Box {...props}>
			{/* <MenuItem urlString="/">Home</MenuItem> */}
			<MenuItem urlString="/our-story">Our Story</MenuItem>
			<MenuItem urlString="/quality">Quality</MenuItem>
			<MenuItem urlString="/market-guide">Market Guide</MenuItem>
			<MenuItem urlString="/blog">News</MenuItem>
			<MenuItem urlString="/season-calendar">Seasons</MenuItem>
			<MenuItem urlString="/contact">Contact</MenuItem>
			<Box
				// display={{ base: show ? 'block' : 'none', lg: 'block' }}
				mt={{ base: 4, md: 0 }}
				ml="auto"
				textAlign="right"
			>
				<Button
					secondary
					ml="auto"
					mr={{ base: 0, lg: 2 }}
					as="a"
					href="http://orders.thompsonfresh.com.au"
				>
					Order Online
				</Button>
				<Button as="a" href="/sign-up" mt={{ base: 2, lg: 0 }}>
					Become a customer
				</Button>
			</Box>
		</Box>
	);
};

export const Header = (props) => {
	const [show, setShow] = React.useState(false);
	const handleToggle = () => setShow(!show);

	return (
		<Flex
			as="nav"
			flexDirection="row"
			wrap="wrap"
			padding="1.5rem"
			{...props}
			height="75px"
			bg="white"
			justifycontent="left"
		>
			<Flex align="center" mr={5}>
				<Link
					href="/"
					css={css`
						&:hover {
							text-decoration: none;
						}
					`}
				>
					<Image
						src={Logo}
						fallbackSrc={'./logo.svg'}
						size={150}
						position="absolute"
						top={3}
						zIndex="50"
					/>
				</Link>
			</Flex>

			<Box
				display={{ base: 'block', lg: 'none' }}
				position={{ base: 'absolute', lg: 'relative' }}
				top="2rem"
				right="2rem"
				onClick={handleToggle}
				ml="auto"
				zIndex={99}
			>
				<HamburgerMenu
					isOpen={show}
					// menuClicked={this.handleClick.bind(this)}
					width={28}
					height={20}
					strokeWidth={2}
					rotate={0}
					color="#4eb75d"
					borderRadius={0}
					animationDuration={0.2}
				/>
			</Box>

			<Flex
				alignItems="center"
				ml="auto"
				display={{ base: show ? 'block' : 'none', lg: 'flex' }}
				textAlign={{ base: 'right', lg: 'left' }}
				bg="white"
				zIndex="98"
				rounded="6px"
				boxShadow={{ base: 'md', lg: 'unset' }}
				// width={{ sm: "full", md: "auto" }}
				// flexGrow={1}
			>
				<MenuItems
					w={{ base: '300px', lg: 'unset' }}
					p={{ base: 5, lg: 'unset' }}
					d={{ base: 'block', lg: 'flex' }}
				/>
			</Flex>
		</Flex>
	);
};

// export default Header;
