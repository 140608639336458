/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import {
	Box,
	Heading,
	Flex,
	Text,
	Button,
	Link,
	Icon,
	FlexProps,
} from '@chakra-ui/core';

export const Footer = (props: FlexProps) => {
	const [show, setShow] = React.useState(false);
	const handleToggle = () => setShow(!show);

	return (
		<Flex
			as="footer"
			flexDirection="column"
			py="1.5rem"
			w="100%"
			bottom="0px"
			{...props}
			justifyContent="space-between"
			// alignItems="center"
			mt={10}
			color="gray.700"
		>
			{/* First main row */}
			<Flex
				w="100%"
				flexDirection="row"
				alignItems="center"
				wrap="wrap"
				justifyContent="space-between"
				mb={3}
			>
				<Flex flexDirection="column" minW="100px">
					<Text maxWidth="200px">
						Thompson Fresh Produce Pty Ltd
						<br />
						Farm Produce Merchants
						<br />
						Stand 213-214 C Shed
						<br />
						PO Box 110
						<br />
						Sydney Markets NSW 2129
					</Text>
				</Flex>
				<Flex flexDirection="column" mb="auto" minW="100px">
					<Link href="/">Home</Link>
					<Link href="/our-story">Our Story</Link>
					<Link href="/quality">Quality</Link>
					<Link href="/market-guide">Market Guide</Link>
				</Flex>
				<Flex flexDirection="column" mb="auto" minW="100px">
					<Link href="/blog">News</Link>
					<Link href="/contact">Contact</Link>
					<Link href="/sign-up">Become a Customer</Link>
					<Link href="/season-calendar">Season Calendar</Link>
				</Flex>
				<Flex flexDirection="column" mb="auto" minW="100px">
					<Link href="https://www.instagram.com/Thompsonfresh/" alt="Instagram">
						<Icon name="instagram" size="19px" mr={3} />
						Instagram
					</Link>
					<Link href="https://www.facebook.com/thompsonfresh/" alt="Facebook">
						<Icon name="facebook" size="19px" mr={3} />
						Facebook
					</Link>
				</Flex>
			</Flex>
			{/* Final row with copyright */}
			<Flex
				flexDirection="row"
				wrap="wrap"
				justifyContent="space-between"
				mt={5}
				w="100%"
			>
				<Text fontSize="sm">
					© {new Date().getFullYear()} Thompson Fresh Pty Ltd. All rights
					reserved.
				</Text>
				<Flex flexDirection="row" color="grey" px={2}>
					<Link href="/terms-and-conditions" mr={3}>
						Terms of Service
					</Link>
					<Link href="/privacy-policy">Privacy Policy </Link>
				</Flex>
			</Flex>
		</Flex>
	);
};

// export default Footer;
