import React, { ReactNode } from 'react';
import { SEO } from './seo';
import { Header } from './header';
import { Footer } from './footer';
import { Box, Flex } from '@chakra-ui/core';
import styled from '@emotion/styled';
import theme from '../gatsby-plugin-chakra-ui/theme';
import { Container } from './Container';

interface Props {}

export const BoxWithWebCSS = styled(Box)`
	/*
		This can be used as a base index.css 

	  */
	/* 
	background: white;
	color: ${theme.colors.gray['900']}; 
	*/
	color: ${theme.colors.gray[900]};
`;

export const PageLayout = ({ children }) => {
	return (
		<BoxWithWebCSS bg="white" color="gray.900">
			<SEO />
			<Header />
			{children}
			<Container>
				<Footer />
			</Container>
		</BoxWithWebCSS>
	);
};
